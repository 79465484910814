(function($) {

	'use strict';

	var pluginName = 'menuMore';

	function Plugin(element, options) {
		this.$menu = $(element);
		this.$menuContainer = this.$menu.closest('[data-role="menu-more-container"]');
		this.$menuItems = this.$menu.find('> li:not([data-role="menu-more-button"])');
		this.$moreButton = this.$menu.find('[data-role="menu-more-button"]');
		this.$moreDropdown = this.$moreButton.find('[data-role="menu-more-dropdown"]');
		this.$moreDropdownItems;
		this.init();
	}

	Plugin.prototype.calc = function() {

		var self = this;

		self.$menuItems.removeClass('hidden');
		self.$moreDropdownItems.addClass('hidden');

		var menuContainerWidth = self.$menuContainer.width();
		var menuWidth = self.$menu.width();
		if (menuContainerWidth < menuWidth) {
			self.$moreButton.removeClass('hidden');
			$(self.$menuItems.get().reverse()).each(function (i) {
				menuContainerWidth = self.$menuContainer.width() - self.$moreButton.width();
				menuWidth = self.$menu.width() - self.$moreButton.width();
				if (menuContainerWidth < menuWidth) {
					$(this).addClass('hidden');

					$(self.$moreDropdownItems.get().reverse()).eq(i).removeClass('hidden');
				}
			});
		}
		else {
			self.$moreButton.addClass('hidden');
		}
	}

	Plugin.prototype.init = function() {

		var self = this;

		self.$menuItems.clone().appendTo(self.$moreDropdown);
		self.$moreDropdownItems = self.$moreDropdown.find('> li');
		self.$moreDropdownItems.addClass('hidden');

		self.calc();

		self.$menuContainer.addClass('is-initialized');

		$(window).resize(function () {
			self.calc();
		});

	}

	$.fn[pluginName] = function(options) {
		return this.each(function() {
			if (!$.data(this, 'plugin_' + pluginName)) {
				$.data(this, 'plugin_' + pluginName,
					new Plugin(this, options));
			}
		});
	};

}(jQuery));